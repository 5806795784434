import { render, staticRenderFns } from "./announcementCenterList.vue?vue&type=template&id=2749937c&scoped=true"
import script from "./announcementCenterList.vue?vue&type=script&lang=js"
export * from "./announcementCenterList.vue?vue&type=script&lang=js"
import style0 from "./announcementCenterList.vue?vue&type=style&index=0&id=2749937c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2749937c",
  null
  
)

export default component.exports